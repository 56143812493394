<!--
SPDX-FileCopyrightText: 2021 Lightmeter <hello@lightmeter.io>

SPDX-License-Identifier: AGPL-3.0-only
-->

<template>
  <span
    ><strong
      >SPANK SPANK: Insight import summary not implemented for
      {{ insight.content_type }}!!!!!</strong
    ></span
  >
</template>

<script>
export default {
  props: {
    insight: Object
  }
};
</script>
