var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"results mt-4"},[_vm._l((_vm.results),function(result,resultIndex){return _c('ul',{key:resultIndex,staticClass:"detective-result-cell card list-unstyled"},[_c('li',{staticClass:"card-body"},[_c('ul',{staticClass:"status-list list-unstyled"},[_vm._l((result.entries),function(delivery,statusIndex){return _c('li',{key:statusIndex,class:_vm.statusClass(delivery.status),attrs:{"title":_vm.statusTitle(delivery.status)}},[_vm._v(" "+_vm._s(delivery.status)+" ")])}),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpired(result)),expression:"isExpired(result)"}],class:_vm.statusClass('expired'),attrs:{"title":_vm.statusTitle('expired')}},[_vm._v(" expired ")])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showQueues),expression:"showQueues"},{name:"translate",rawName:"v-translate",value:({ queue: result.queue }),expression:"{ queue: result.queue }"}],staticClass:"queue-name card-text"},[_vm._v(" Queue ID: %{queue} ")]),_c('ul',{staticClass:"list-unstyled card-text"},_vm._l((result.entries),function(delivery,deliveryIndex){return _c('li',{key:deliveryIndex,staticClass:"mt-3 card-text"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasMultipleDeliveryAttempts(delivery)),expression:"hasMultipleDeliveryAttempts(delivery)"},{name:"translate",rawName:"v-translate",value:({
              attempts: _vm.formatAttempts(delivery),
              status: _vm.formatMultipleStatus(delivery),
              code: _vm.formatMultipleDsn(delivery),
              begin: _vm.formatMinTime(delivery),
              end: _vm.formatMaxTime(delivery)
            }),expression:"{\n              attempts: formatAttempts(delivery),\n              status: formatMultipleStatus(delivery),\n              code: formatMultipleDsn(delivery),\n              begin: formatMinTime(delivery),\n              end: formatMaxTime(delivery)\n            }"}],staticClass:"mt-3 card-text",attrs:{"render-html":"true"}},[_vm._v(" %{attempts} delivery attempts %{status} with status code %{code} from %{begin} to %{end} ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasMultipleDeliveryAttempts(delivery)),expression:"!hasMultipleDeliveryAttempts(delivery)"},{name:"translate",rawName:"v-translate",value:({
              status: _vm.formatMultipleStatus(delivery),
              code: _vm.formatMultipleDsn(delivery),
              time: _vm.formatMinTime(delivery)
            }),expression:"{\n              status: formatMultipleStatus(delivery),\n              code: formatMultipleDsn(delivery),\n              time: formatMinTime(delivery)\n            }"}],staticClass:"mt-3 card-text",attrs:{"render-html":"true"}},[_vm._v(" Message %{status} with status code %{code} at %{time} ")])])}),0)])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStatusCodeMoreInfo),expression:"showStatusCodeMoreInfo"},{name:"translate",rawName:"v-translate"}],attrs:{"render-html":"true"}},[_vm._v(" More on status codes: %{openLink}IANA's reference list%{closeLink} ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }